<script >

import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import Banner from "@/components/Banner.vue";
import {ref} from "@vue/composition-api";
import {Drequest} from "@/plugins/Drequest";
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiInformationOutline, mdiSquareEditOutline,
  mdiCommentOutline,
  mdiClose
} from "@mdi/js";
import ChapterListItemComponent from "@/components/comicbook/ChapterListItemComponent.vue";

export default {
  components: {FollowToggleComponent, Banner, ChapterListItemComponent},
  props: {
    comicbook: Object,
    user_id: String,
    model: String,
  },
  setup(props) {

    const loader = ref({
      detail: true,
    })
    const nextpage = ref(1)
    const ll = ref({per_page: 9, listEntity:[]})
    let url = ''
    if (props.user_id) {
      url = `&user_id=${props.user_id}`
    }
    const init = (page) => {
      nextpage.value = page

      loader.value.detail = true
      Drequest.api(`chapter.lazyloading?dfilters=on&next=${page}&dsort=number asc&per_page=9&comicbook_id:eq=${props.comicbook.id}${url}`)
        .param(
          {
            "category.slug:eq": props.model,
            ...(props.user_id != props.comicbook.user_id ? {'status:eq': 1} : {})
          }
        )
        .get(response => {
          console.log(response)
          loader.value.detail = false
          ll.value = response
        })

    }
    init(1)

    const deleteChap = (item, index) => {

      if (!confirm('Confirmer la suppression de ce One shot?.'))
        return 0;

      Drequest.api('chapter.delete?id=' + item.id)
        .get(response => {
          console.log(response)
          ll.value.listEntity.slice(index, 1)
        })

    }

    return {
      init,
      deleteChap,
      ll,
      nextpage,
      loader,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
        mdiSquareEditOutline,
        mdiCommentOutline,
        mdiClose,
      },
    }
  }
}

</script>

<template>

  <div
  >
    <v-card-title color="deep-purple darken-3">
      Chapitres
      <v-spacer></v-spacer>
      <template v-if="user_id && user_id == comicbook.user_id">

        <v-btn small :to="'/comicbook/new-chapter/'+comicbook.id">
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          Ajouter un chapitre
        </v-btn>
      </template>
    </v-card-title>
    <v-card-subtitle color="deep-purple darken-3">
      Chapitres de :
      <v-chip
        v-for="page in ll.pagination"
        :key="page"
        :color="nextpage == page?'primary': ''"
        @click="init(page)"
      >
        {{ ((page - 1) * ll.per_page) + 1 }} - {{ page * ll.per_page }}
      </v-chip>
    </v-card-subtitle>
    <v-card-text>
      <v-list v-if="!user_id || user_id != comicbook.user_id" dense class="pa-0">
        <template v-for="(item, index) in ll.listEntity">
<!--          <v-divider
            :key="index"
          ></v-divider>-->
          <chapter-list-item-component
            :item="item" :owner="false"></chapter-list-item-component>
        </template>
      </v-list>
      <v-list v-else dense>
        <template v-for="(item, index) in ll.listEntity">
          <!--          <v-divider
                      :key="index"
                    ></v-divider>-->
          <chapter-list-item-component
            :item="item" :owner="true" @deleteItem="deleteChap"></chapter-list-item-component>
        </template>
      </v-list>
      <v-sheet v-if="ll.listEntity.length === 0"
               elevation="0"
               max-width="600"
               rounded="lg"
               width="100%"
               class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="warning"
          size="112"
        >{{ icons.mdiExclamation }}
        </v-icon>

        <p class="  mb-6">Aucun element trouve</p>

      </v-sheet>
    </v-card-text>

    <v-fab-transition v-if="user_id && user_id == comicbook.user_id">
      <v-btn :to="'/comicbook/new-chapter/'+comicbook.id"
             color="primary"
             rounded
             fixed
             large
             dark
             bottom
             right
             class="v-btn--example mb-15"
             style="z-index: 100"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        Nouveau Chapitre
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<style scoped lang="scss">

</style>
