<script>

import {
  mdiBookOpenPageVariantOutline,
  mdiCommentOutline,
  mdiExclamation,
  mdiEyeOffOutline,
  mdiEyeOutline, mdiHeart, mdiHeartOutline,
  mdiInformationOutline, mdiNote
} from "@mdi/js";
import {Drequest} from "@/plugins/Drequest";
import {ref} from "@vue/composition-api";

export default {
  components: {},
  props: {
    user_id: null | String | Number,
    comicbook: Object,
  },
  data() {
    return {
      icons: {
        mdiExclamation,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
        mdiCommentOutline,
        mdiHeartOutline,
        mdiHeart,
        mdiNote,
        mdiBookOpenPageVariantOutline,
      },
    }
  },
  mounted() {
    this.stats[0].value = this.comicbook.nbkola;
    this.stats[1].value = this.comicbook.nbcomments;
    this.stats[2].value = this.comicbook.nbpage;
    this.stats[3].value = this.comicbook.nbviews;
  },
  setup(props) {

    const stats = ref([
      {icon: 'mdiHeart', label: 'Like', value: 0},
      {icon: 'mdiCommentOutline', label: 'Commentaires', value: 0},
      {icon: 'mdiBookOpenPageVariantOutline', label: 'Pages', value: 0},
      {icon: 'mdiEyeOutline', label: 'Nb Vues', value: 0},
    ])
    const loader = ref({
      detail: true,
      loading: true,
    })
    const post = ref({})
    const loadPost = () => {
      Drequest.api(`detail.post?id=${props.comicbook.post_id}${props.user_id ? `&user_id=${props.user_id}` : ''}`)
        .get(response => {
          console.log(response)
          loader.value.loading = false
          post.value = response.post
        })
    }
    loadPost()
    return {
      stats,
      loader,
      post,
    }
  }
}
</script>

<template>

  <v-row dense >
    <v-col v-for="stat in stats" cols="6" lg="3" md="3">
      <v-card outlined class="ma-0 py-0"
      >
        <div class="d-flex flex-no-wrap  justify-start align-center">

<!--          <v-avatar size="30"
                    class="ma-3 my-0"
                    rounded="0"
          >
            <v-icon >{{ icons[stat.icon] }}</v-icon>
          </v-avatar>-->
          <div>
            <v-card-title class="text-h6 pa-2 py-3">

              <h5>{{ stat.value }}</h5>

            </v-card-title>
            <v-card-subtitle class=" pa-2">{{stat.label}}</v-card-subtitle>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">

</style>
